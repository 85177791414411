import React, { useState } from "react";
import classNames from "classnames";
import Logo from "../images/logo.svg";
import useWindowScrollPosition from "../hooks/window-scroll-position";

export default function Header({ toggle, setToggle }) {
  const position = useWindowScrollPosition({
    throttle: 300
  });
  const handleToggle = () => {
    setToggle(!toggle);
  };

  const navCls = classNames({ "nav--extended": toggle || position.y > 0 });
  return (
    <header className={`mx-auto  z-50  fixed  top-0 right-0 left-0 ${navCls}`}>
      <div className="container mx-auto">
        <nav className="flex  h-16 sm:h-20 px-4 sm:px-0 justify-between items-center text-white">
          <a className="p-2 rounded" href="/">
            <img alt="logo" src={Logo} />
          </a>
          <div className="hidden md:flex justify-between items-center  ">
            <a className="mr-6 py-1 px-2  rounded " href="#solution">
              解决方案
            </a>
            <a className="mr-6  py-1 px-2  rounded" href="#price">
              价格
            </a>
            <a className="py-1 px-2  rounded" href="#client">
              客户案例
            </a>
          </div>
          <div className="hidden md:flex justify-between items-center  ">
            <a className="mr-5 py-1 px-2 rounded" href="#">
              登录
            </a>
            <a className="border-white border rounded-full px-6 py-2 " href="#">
              开始使用
            </a>
          </div>
          <div className=" md:hidden">
            <button
              className="text-gray-400 hover:text-white foucs:text-white"
              onClick={handleToggle}
            >
              <svg className="h-6 w-6 fill-current" viewBox="0 0 18 14">
                <path
                  clipRule="evenodd"
                  d="M0 1a1 1 0 011-1h16a1 1 0 110 2H1a1 1 0 01-1-1zm0 6a1 1 0 011-1h16a1 1 0 110 2H1a1 1 0 01-1-1zm1 5a1 1 0 100 2h16a1 1 0 100-2H1z"
                  fillRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </nav>
      </div>
      {toggle ? (
        <div className="md:hidden py-2 text-white text-sm font-semibold rounded mx-auto text-center bg-purple-100 w-full">
          <a className="block py-2" href="#solution">
            解决方案
          </a>
          <a className="block py-2" href="#price">
            价格
          </a>
          <a className="block py-2" href="#client">
            客户案例
          </a>
          <a className="block py-2" href="#">
            登录
          </a>
        </div>
      ) : null}
    </header>
  );
}
