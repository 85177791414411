import PropTypes from "prop-types";
import React, { useState } from "react";

import Header from "./header";
import Footer from "./footer";

function Layout({ children }) {
  const [toggle, setToggle] = useState(false)
  const handleClick = (e) => {
    setToggle(false);
  }
  return (
    <div className="h-full font-normal">
      <Header setToggle={setToggle} toggle={toggle} />
      <main onClick={handleClick}>
        {children}
      </main>

      <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
