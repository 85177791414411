import React from "react";

export default function Footer() {
  return (
    <footer>
      <div className="footer-top mx-auto text-center text-white">
        <div className="text-sm pt-24">
          立即动手搭建一个属于您企业的专属工作平台吧！
        </div>
        <button className="block mx-auto  mt-10  p-button mt-10">
          开始使用
        </button>
      </div>
      <div className="mx-auto">
        <div className="text-center h-40  copyright">
          <p className="text-sm">Copyright © 2021 斯马千网络科技有限公司</p>
          <p className="text-sm"><a href="https://beian.miit.gov.cn/">苏ICP备17016985号</a></p>
        </div>
      </div>
    </footer>
  );
}
